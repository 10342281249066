@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Poppins:wght@200;300;400;500;700;900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .list-roman {
    list-style-type: none;
    counter-reset: section;
  }

  .list-roman > li {
    counter-increment: section;
    position: relative;
    padding-left: 2rem;
  }

  .list-roman > li::before {
    content: counter(section, upper-roman) ". ";
    position: absolute;
    font-weight: 500;
    left: 0;
  }
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  @apply font-sans;
  font-weight: 400; /* Default weight */
  cursor: default;
  height: 100%;
}

.mobile-margin-top {
  margin-top: 215px;
}

.slick-prev:before,
.slick-next:before {
  font-size: 45px !important;
  color: black !important;
}
.tipos.slick-prev:before,
.tipos.slick-next:before {
  font-size: 45px !important;
  color: whitesmoke !important;
}
