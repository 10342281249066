.hero-section {
  width: 100%;
  height: 100%;
  position: relative;
}

.slick-prev,
.slick-next {
  z-index: 10;
}

.slick-dots li button:before {
  color: white; /* ou qualquer cor que você desejar */
}

.slick-dots li.slick-active button:before {
  color: white; /* ou qualquer cor que você desejar */
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.object-cover {
  object-fit: cover;
}
