.navbar-global-section {
  display: flex;
  top: 0;
  margin: 0;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 9;
  width: 100%;
  background: white;
}
.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-bottom: 16px;
}

@media (max-width: 400px) {
  .navbar-global-section {
    position: static;
  }
}
