.App {
  text-align: center;
}

.carousel .thumbs-wrapper.axis-vertical {
  margin: 0;
}
.carousel-status {
  display: none;
}
