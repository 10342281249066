.footer-container {
  border-top: 1px solid #6161618a; /* Cor e espessura da linha */
  height: fit-content;
  display: flex;
  width: 100%;
  margin: 2% 0 2% 0;
}
.footer-content {
  display: flex;
  padding-top: 20px; /* Espaçamento acima da linha (opcional) */
  justify-content: space-evenly;
  width: 100%;
}

.footer-menu {
  display: flex;
}
.footer-menu-container {
  width: 600px;
  padding: 0;
  margin: 0;
  gap: 100px;
  display: flex;
  min-width: fit-content;
  list-style: none;
}

.footer-menu-container li {
  width: 100%;
  line-height: 48px;
}
.footer-menu-container li > h3 {
  margin: 0;
}
.footer-app-container {
  display: flex;
  justify-content: center;
}
.footer-app {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

@media (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-content {
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
  }

  .footer-menu {
    flex-direction: column;
    align-items: center;
  }

  .footer-menu-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 20px;
  }

  .footer-menu-container li {
    width: 90%; /* or whatever percentage or fixed width you'd prefer for mobile */
    text-align: center; /* if you want the list items centered */
  }

  .footer-app-container {
    margin-top: 20px; /* optional spacing between the menu and app sections */
  }
}
