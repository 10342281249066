
.CardProducts-Home-Title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  margin: 55px 0 55px 0;
}
.CardProducts-Home-Title h1 {
  color: #000;
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 4.32px;
}

.video-container {
  margin: 0 0 1% 0;
  width: 100%;
  position: relative;
}

.video-title {
  width: 415px;
  color: #fff;
  position: absolute;
  top: 30%;
  left: 7%;
  font-size: 60px;
  font-family: "Abril Fatface";
  line-height: normal;
  font-weight: 400;
  line-height: 62.89px;
}

.video-description {
  width: 338.499px;
  height: 429.55px;
  flex-shrink: 0;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 7%;
  font-size: 17.458px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.89px;
}
.home-revendedora-banner {
  margin: 0 0 1% 0;
  width: 100%;
  position: relative;
}
.revendedora-banner-texts {
  margin: 0 0 1% 0;
  width: 100%;
  position: relative;
}
.revendedora-banner-texts h1 {
  color: #fff;
  position: absolute;
  top: 10%;
  left: 50%;
  font-size: 60px;
  font-family: "Abril Fatface";
  line-height: 62.89px;
  font-weight: 400;
}
.revendedora-banner-texts p {
  width: 30%;
  flex-shrink: 0;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 17.458px;
  font-style: normal;
  font-weight: 400;
  line-height: 22.89px;
}
.revendedora-banner-link {
  text-decoration: none;
  align-self: self-start;
  color: inherit;
  position: absolute;
  top: 80%;
  left: 50%;
}
.revendedora-banner-button {
  text-align: center;
  background-color: white;
  color: black;
  width: 200px;
  border: none;
  border-radius: 22px;
  padding: 10px 20px;
}
.revendedora-banner-button span {
  color: #282828;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 30.003px; /* 120% */
}

/* Responsive classes */
@media (max-width: 400px) {

  .video-title {
    max-width: 100px;
    word-break: break-all;
    width: 100%;
    height: 100%;
    line-height: 15.89px;
    font-size: 15px; /* Smaller font size for mobile */
    left: 2.5%; /* Adjust position for smaller screens */
    top: 5%; /* Adjust position for smaller screens */
  }
  .video-description {
    max-width: 190px;
    width: 100%;
    height: 100%;
    line-height: 10.89px;
    font-size: 7px; /* Smaller font size for mobile */
    left: 1%; /* Adjust position for smaller screens */
    top: 28%; /* Adjust position for smaller screens */
    width: 35%;
  }
  .revendedora-banner-texts h1 {
    width: 100%;
    height: 100%;
    max-width: 190px;
    line-height: 15px;
    font-size: 15px; /* Smaller font size for mobile */
    left: 50%; /* Adjust position for smaller screens */
    top: 1%; /* Adjust position for smaller screens */
  }
  .revendedora-banner-texts p {
    max-width: 190px;
    width: 100%;
    height: 100%;
    line-height: 9.89px;
    font-size: 8px; /* Smaller font size for mobile */
    left: 50%; /* Adjust position for smaller screens */
    top: 35%; /* Adjust position for smaller screens */
  }
  .revendedora-banner-link {
    top: 75%;
    left: 50%;
    font-size: 12px;
  }
  .revendedora-banner-button {
    width: 100px;
    font-size: 12px;
    padding: 2px 2px;
  }
  .revendedora-banner-button span {
    font-size: 12px;
    line-height: 0;
  }
}

@media (min-width: 1024px) {
  /* lg breakpoint */
  .home-belowHS-p {
    padding-left: 2rem; /* 32px */
    padding-right: 2rem; /* 32px */
  }
}
