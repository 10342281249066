.topbar-container {
  display: flex;
  height: 2.5rem; /* 40px if 1rem = 16px */
  align-items: center;
  justify-content: center;
  background-color: #c9c9c9;
  padding-left: 1rem; /* 16px */
  padding-right: 1rem; /* 16px */
  font-size: 16px;
  font-weight: 400;
  color: inherit;
  width: 100%;
}

.topbar-content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.topbar-cupom-item {
  font-weight: 400;
  text-align: center;
}
.topbar-cupom {
  display: inline-block; /* Makes the span behave like a block but within inline context */
  margin: 0 0 0 0.5rem; /* Margin between the texts */
  width: 250px; /* Set the width */
  height: 30px; /* Set the height */
  line-height: 30px; /* Centers the text vertically */
  text-align: center; /* Centers the text horizontally */
  vertical-align: middle; /* Additional vertical centering for inline context */
  color: white; /* Color of the text set as needed */
  background: black; /* Color of the background set as needed */
  border-radius: 20px; /* Border Radius set as needed */
  font-size: 14px;
}
.topbar-cupom-code {
  cursor: text;
  text-align: center;
}

.topbar-without-code {
  display: flex;
  height: 2.5rem; /* 40px if 1rem = 16px */
  margin: 10px 0 0 0;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  --tw-gradient-from: rgba(252, 205, 186, 1); /* Tailwind's green color */
  --tw-gradient-stops: var(--tw-gradient-from),
    var(--tw-gradient-to, rgba(16, 185, 129, 0));
  --tw-gradient-via: rgba(
    252,
    205,
    186,
    1
  ); /* A light blue color, adjust as needed */
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-via),
    var(--tw-gradient-to, rgba(96, 165, 250, 0));
  --tw-gradient-to: #f8ca9d; /* A dark blue color, adjust as needed */
  padding-left: 1rem; /* 16px */
  padding-right: 1rem; /* 16px */
  font-size: 18px;
  font-weight: lighter;
  color: #000000;
  width: 100%;
  margin: 0;
}

@media (max-width: 768px) {
  .topbar-cupom-item {
    font-size: 12px; /* Size of the text */
  }
  .topbar-cupom {
    width: 150px; /* Set the width */
    height: 30px; /* Set the height */
    line-height: 30px; /* Centers the text vertically */
    font-size: 10px;
  }
}
